import React, { useState, useEffect, useContext } from "react";
import "./../products/products.css";
import Product from "components/Product";
import { useLocation } from "react-router-dom";
import Error from "./../../assets/error.png";
import Footer from "components/Footer";
import { SyncLoader } from "react-spinners";
import "../../fonts/fonts.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CartContext from "CartContext";

const BundlesAndGifts = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const { addToCart } = useContext(CartContext);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  useEffect(() => {
    setLoading(true);

    const queryParams = new URLSearchParams(location.search);
    const searchQuery = queryParams.get("q");

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/getAllBundlesAndGifts${
      searchQuery ? `?q=${encodeURIComponent(searchQuery)}` : ""
    }`;
    

    fetch(apiUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.search]);

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      ) : error ? (
        <div className="error-container">
          <img src={Error} alt="error" />
          <div className="error-text">{t("OOPS! No Products Found")}</div>
        </div>
      ) : (
        <>
          <div className="products-web-view">
            <div className="product-container">
              <div className="shop-all">{t("pc-navbar-menu-gifts-text")}</div>
              {products.length === 0 ? (
                <div className="global-full-page">{t("no_products")}</div>
              ) : (
                <div className="products-column">
                  {products.map((product, index) => (
                    <Product
                      key={index}
                      product={product}
                      setLoading={setLoading}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="products-mobile-view">
            <div className="shop-all"> {t("shop_all")}</div>
            {products.length === 0 ? (
              <div className="global-full-page">{t("no_products")}</div>
            ) : (
              <div className="products-column-mobile">
                {products.map((product, index) => {
                  const buttonColor = "#FDDCCD";
                  const thumbnailImage = product.medias.find(
                    (media) => media.tag === "thumbnail"
                  );
                  const bundleFlag = product.type === "bundle" ? true : false;
                  const productCleanse = product.id === 12 ? true : false;
                  const productPortable = product.id === 13 ? true : false;

                  return (
                    <div key={index} className="product-item-mobile">
                      <Link
                        to={`/product/${encodeURIComponent(
                          product.name_en.replace(/\s+/g, "-").toLowerCase()
                        )}/${product.id}`}
                      >
                        <div className="product-mobile">
                          {/* {bundleFlag && (
                            <div className="sale-badge">{t("sale_50")}</div>
                          )} */}
                          {productCleanse && (
                            <div className="sale-badge">{t("sale_1")}</div>
                          )}
                          {productPortable && (
                            <div className="sale-badge">{t("sale_2")}</div>
                          )}

                          <img
                            src={
                              thumbnailImage
                                ? thumbnailImage.image_url
                                : product.medias[0].image_url
                            }
                            alt={product.tag}
                          />

                          <div className="products-name">
                            {" "}
                            {product[`name_${i18n.language.slice(-2)}`]}{" "}
                          </div>
                          <button
                            className="add-to-cart-product-mobile"
                            style={{ background: buttonColor }}
                            onClick={(event) =>
                              handleAddToCart(product.id, event)
                            }
                          >
                            {product.type === "bundle" ? (
                              <>
                                {t("add_to_cart")} -{" "}
                                <span
                                  style={{
                                    marginRight: isArabic ? "0px" : "7px",
                                    marginLeft: isArabic ? "7px" : "0px",
                                  }}
                                  class="strikethrough"
                                >
                                  {product.id === 15
                                    ? "793"
                                    : product.id === 16
                                    ? "698"
                                    : "304"}{" "}
                                  {t("SAR")}
                                </span>
                                {product.price} {t("SAR")}
                              </>
                            ) : (
                              <>
                                {t("add_to_cart")} - {product.price} {t("SAR")}
                              </>
                            )}
                          </button>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
      <Footer />
    </main>
  );
};

export default BundlesAndGifts;
