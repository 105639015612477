import React, { useEffect, useState, useContext } from "react";
import "../../styles/cart.css";
import CounterBox from "components/CounterBox";
import BlackLine from "../../assets/Line 3.svg";
import { SyncLoader } from "react-spinners";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import Input from "scenes/signUp/Input";
import User from "User";
import Checkbox from "@mui/material/Checkbox";
import Select, { components } from "react-select";
import { Country } from "country-state-city";
import downArrow from "../../assets/chevron-down.svg";
import Button from "../signUp/Button";
import validateEmail from "scenes/signUp/utils"; 
import { useNavigate } from "react-router-dom";
import CartContext from "CartContext";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";

import ErrorIcon from "../signUp/Error";
import Valid from "../signUp/Valid";

const Cart = () => {
  const [loading, setLoading] = useState(true);
  const [cartEmpty, setCartEmpty] = useState(false);
  const [products, setProducts] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [cart, setCart] = useState(false);
  const [authenticated, setAuthenticated] = useState(null);
  const [receiveEmailsChecked, setReceiveEmailsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [citySelectedOptions, setCitySelectedOptions] = useState([]);
  const [saveAddress, setSaveAddress] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const { fetchCartCount } = useContext(CartContext);
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);



  const cityOptionsByCountry = {
    SA: [
      { value: "Abha", label: "Abha" },
      { value: "Ad Dilam", label: "Ad Dilam" },
      { value: "Ad Diriyah", label: "Ad Diriyah" },
      { value: "Afif", label: "Afif" },
      { value: "Ahad Al Musarihah", label: "Ahad Al Musarihah" },
      { value: "Ahad Rafidah", label: "Ahad Rafidah" },
      { value: "Al Aflaj (Layla)", label: "Al Aflaj (Layla)" },
      { value: "Al Badai", label: "Al Badai" },
      { value: "Al Bashayer", label: "Al Bashayer" },
      { value: "Al Baha", label: "Al Baha" },
      { value: "Al Bukayriyah", label: "Al Bukayriyah" },
      { value: "Al Ghat", label: "Al Ghat" },
      { value: "Al Hariq", label: "Al Hariq" },
      { value: "Al Hofuf", label: "Al Hofuf" },
      { value: "Al Jubail", label: "Al Jubail" },
      { value: "Al Jumum", label: "Al Jumum" },
      { value: "Al Kharj", label: "Al Kharj" },
      { value: "Al Khafji", label: "Al Khafji" },
      { value: "Al Khubar", label: "Al Khubar" },
      { value: "Al Khurmah", label: "Al Khurmah" },
      { value: "Al Majardah", label: "Al Majardah" },
      { value: "Al Majma'ah", label: "Al Majma'ah" },
      { value: "Al Midhnab", label: "Al Midhnab" },
      { value: "Al Muzahimiyah", label: "Al Muzahimiyah" },
      { value: "Al Qatif", label: "Al Qatif" },
      { value: "Al Qurayyat", label: "Al Qurayyat" },
      { value: "Al Wajh", label: "Al Wajh" },
      { value: "Al-Dawadmi", label: "Al-Dawadmi" },
      { value: "An Nuayriyah", label: "An Nuayriyah" },
      { value: "Ar Rass", label: "Ar Rass" },
      { value: "Arar", label: "Arar" },
      { value: "As Sulayyil", label: "As Sulayyil" },
      { value: "Ash Shinan", label: "Ash Shinan" },
      { value: "Ash Shimasiyah", label: "Ash Shimasiyah" },
      { value: "Aqiq", label: "Aqiq" },
      { value: "Az Zulfi", label: "Az Zulfi" },
      { value: "Badr", label: "Badr" },
      { value: "Billasmar", label: "Billasmar" },
      { value: "Bishah", label: "Bishah" },
      { value: "Buqayq", label: "Buqayq" },
      { value: "Buraydah", label: "Buraydah" },
      { value: "Dammam", label: "Dammam" },
      { value: "Dawmat Al Jandal", label: "Dawmat Al Jandal" },
      { value: "Dhahran", label: "Dhahran" },
      { value: "Dhurma", label: "Dhurma" },
      { value: "Duba", label: "Duba" },
      { value: "Hafar al-Batin", label: "Hafar al-Batin" },
      { value: "Haqil", label: "Haqil" },
      { value: "Hail", label: "Hail" },
      { value: "Hawtat Bani Tamim", label: "Hawtat Bani Tamim" },
      { value: "Inak", label: "Inak" },
      { value: "Jazan", label: "Jazan" },
      { value: "Jeddah", label: "Jeddah" },
      { value: "Jouf", label: "Jouf" },
      { value: "Khamis Mushait", label: "Khamis Mushait" },
      { value: "Khaybar", label: "Khaybar" },
      { value: "Khulais", label: "Khulais" },
      {
        value: "King Abdullah Economic City",
        label: "King Abdullah Economic City",
      },
      { value: "Laith", label: "Laith" },
      { value: "Layla", label: "Layla" },
      { value: "Madinah", label: "Madinah" },
      { value: "Makkah", label: "Makkah" },
      { value: "Muhayil Aseer", label: "Muhayil Aseer" },
      { value: "Mudhaylif", label: "Mudhaylif" },
      { value: "Najran", label: "Najran" },
      { value: "Qaryat Al Ulya", label: "Qaryat Al Ulya" },
      { value: "Qassim", label: "Qassim" },
      { value: "Quwayiyah", label: "Quwayiyah" },
      { value: "Rafha", label: "Rafha" },
      { value: "Rania", label: "Rania" },
      { value: "Ras Tannurah", label: "Ras Tannurah" },
      { value: "Riyad Al Khabra", label: "Riyad Al Khabra" },
      { value: "Riyadh", label: "Riyadh" },
      { value: "Rabigh", label: "Rabigh" },
      { value: "Sabya", label: "Sabya" },
      { value: "Safwa", label: "Safwa" },
      { value: "Sakaka", label: "Sakaka" },
      { value: "Samtah", label: "Samtah" },
      { value: "Sayhat", label: "Sayhat" },
      { value: "Sharurah", label: "Sharurah" },
      { value: "Shaqra", label: "Shaqra" },
      { value: "Shraiee", label: "Shraiee" },
      { value: "Sakaka", label: "Sakaka" },
      { value: "Tabuk", label: "Tabuk" },
      { value: "Taif", label: "Taif" },
      { value: "Tathilith", label: "Tathilith" },
      { value: "Tarut", label: "Tarut" },
      { value: "Thadiq", label: "Thadiq" },
      { value: "Thuwal", label: "Thuwal" },
      { value: "Turaif", label: "Turaif" },
      { value: "Turaib", label: "Turaib" },
      { value: "Turbah", label: "Turbah" },
      { value: "Umluj", label: "Umluj" },
      { value: "Unaizah", label: "Unaizah" },
      { value: "Uqlat Al Suqur", label: "Uqlat Al Suqur" },
      { value: "Wadi El Dwaser", label: "Wadi El Dwaser" },
      { value: "Yanbu", label: "Yanbu" },
      { value: "Zalal", label: "Zalal" },
    ],
    QA: [
      { value: "Al Khor", label: "Al Khor" },
      { value: "AL SHAMAL", label: "AL SHAMAL" },
      { value: "AL UDEID", label: "AL UDEID" },
      { value: "DOHA", label: "DOHA" },
      { value: "DOHA ABU NAKLA", label: "DOHA ABU NAKLA" },
      { value: "DOHA ABU SIDRA", label: "DOHA ABU SIDRA" },
      { value: "DOHA AL DAFNA", label: "DOHA AL DAFNA" },
      { value: "DOHA AL DAYEEN", label: "DOHA AL DAYEEN" },
      { value: "DOHA AL DUHAIL", label: "DOHA AL DUHAIL" },
      { value: "DOHA AL GHARAFA", label: "DOHA AL GHARAFA" },
      { value: "DOHA AL GHUWAIRIYA", label: "DOHA AL GHUWAIRIYA" },
      { value: "DOHA AL HILAL", label: "DOHA AL HILAL" },
      { value: "DOHA AL KEESHA", label: "DOHA AL KEESHA" },
      { value: "DOHA AL KHARATIYAT", label: "DOHA AL KHARATIYAT" },
      { value: "DOHA AL KHULAIFAT", label: "DOHA AL KHULAIFAT" },
      { value: "DOHA AL KHUWAIR", label: "DOHA AL KHUWAIR" },
      { value: "DOHA AL LUQTA", label: "DOHA AL LUQTA" },
      { value: "DOHA AL MESSILA", label: "DOHA AL MESSILA" },
      { value: "DOHA AL RUWAIS", label: "DOHA AL RUWAIS" },
      { value: "DOHA AL SAAD", label: "DOHA AL SAAD" },
      { value: "DOHA AL SAILIYA", label: "DOHA AL SAILIYA" },
      { value: "DOHA AL SHAKAMA", label: "DOHA AL SHAKAMA" },
      { value: "DOHA AL THAKHIRA", label: "DOHA AL THAKHIRA" },
      { value: "DOHA AL WAAB", label: "DOHA AL WAAB" },
      { value: "DOHA AL WAJBA", label: "DOHA AL WAJBA" },
      { value: "DOHA AL WAKRA", label: "DOHA AL WAKRA" },
      { value: "DOHA ALMANSOURA", label: "DOHA ALMANSOURA" },
      { value: "DOHA AZIZYA", label: "DOHA AZIZYA" },
      { value: "DOHA BANI HAJER", label: "DOHA BANI HAJER" },
      { value: "DOHA BIN MAHMOUD", label: "DOHA BIN MAHMOUD" },
      { value: "DOHA BUHAMOUR", label: "DOHA BUHAMOUR" },
      { value: "DOHA DAHL AL HAMAM", label: "DOHA DAHL AL HAMAM" },
      { value: "DOHA FAREEJ AL AMIR", label: "DOHA FAREEJ AL AMIR" },
      { value: "DOHA FEREEJ AL AMIR", label: "DOHA FEREEJ AL AMIR" },
      { value: "DOHA FEREEJ AL KULAIB", label: "DOHA FEREEJ AL KULAIB" },
      { value: "DOHA FEREEJ AL NASR", label: "DOHA FEREEJ AL NASR" },
      { value: "DOHA FEREEJ BIN DERHAM", label: "DOHA FEREEJ BIN DERHAM" },
      { value: "DOHA GHARRFA", label: "DOHA GHARRFA" },
      { value: "DOHA HAMAD INTL AIRPORT", label: "DOHA HAMAD INTL AIRPORT" },
      { value: "DOHA HAZM AL MARKHIYA", label: "DOHA HAZM AL MARKHIYA" },
      { value: "DOHA INDUSTRIAL AREA", label: "DOHA INDUSTRIAL AREA" },
      { value: "DOHA IZGHAWA", label: "DOHA IZGHAWA" },
      { value: "DOHA JEBAILAT", label: "DOHA JEBAILAT" },
      { value: "DOHA JELAIAH", label: "DOHA JELAIAH" },
      { value: "DOHA JEMALIYA", label: "DOHA JEMALIYA" },
      { value: "DOHA KHULAIFATH", label: "DOHA KHULAIFATH" },
      { value: "DOHA LUSAIL", label: "DOHA LUSAIL" },
      { value: "DOHA MADHINAT KHALIFA", label: "DOHA MADHINAT KHALIFA" },
      { value: "DOHA MAMOURA", label: "DOHA MAMOURA" },
      { value: "DOHA MANASEER", label: "DOHA MANASEER" },
      { value: "DOHA MARKHAYA", label: "DOHA MARKHAYA" },
      { value: "DOHA MESAIEED", label: "DOHA MESAIEED" },
      { value: "DOHA MESAIMEER", label: "DOHA MESAIMEER" },
      { value: "DOHA MESSILA", label: "DOHA MESSILA" },
      { value: "DOHA MUAITHER", label: "DOHA MUAITHER" },
      { value: "DOHA MUSHERIEB", label: "DOHA MUSHERIEB" },
      { value: "DOHA NAJMA", label: "DOHA NAJMA" },
      { value: "DOHA NEW AL RAYYAN", label: "DOHA NEW AL RAYYAN" },
      { value: "DOHA NEW INDUSTRIAL AREA", label: "DOHA NEW INDUSTRIAL AREA" },
      { value: "DOHA NEW SALATA", label: "DOHA NEW SALATA" },
      { value: "DOHA NUAIJA", label: "DOHA NUAIJA" },
      { value: "DOHA OLD AIRPORT", label: "DOHA OLD AIRPORT" },
      { value: "DOHA OLD AL RAYYAN", label: "DOHA OLD AL RAYYAN" },
      { value: "DOHA ONAIZA", label: "DOHA ONAIZA" },
      { value: "DOHA PEARL QATAR", label: "DOHA PEARL QATAR" },
      { value: "DOHA RAS ABU ABBOUD", label: "DOHA RAS ABU ABBOUD" },
      { value: "DOHA RAS ABU FONTAS", label: "DOHA RAS ABU FONTAS" },
      { value: "DOHA RAWDA AL - KHAIL", label: "DOHA RAWDA AL - KHAIL" },
      { value: "DOHA RAWDAT RASHEED", label: "DOHA RAWDAT RASHEED" },
      { value: "DOHA RAWDATH AL HAMMAMA", label: "DOHA RAWDATH AL HAMMAMA" },
      { value: "DOHA RUMAILA", label: "DOHA RUMAILA" },
      { value: "DOHA SAHA", label: "DOHA SAHA" },
      { value: "DOHA SHAHANIYA", label: "DOHA SHAHANIYA" },
      { value: "DOHA SHAMAL", label: "DOHA SHAMAL" },
      { value: "DOHA SPORTS CITY", label: "DOHA SPORTS CITY" },
      { value: "DOHA THUMAMA", label: "DOHA THUMAMA" },
      { value: "DOHA UMM AL AMAD", label: "DOHA UMM AL AMAD" },
      { value: "DOHA UMM AL SANEEM", label: "DOHA UMM AL SANEEM" },
      { value: "DOHA UMM BAB", label: "DOHA UMM BAB" },
      { value: "DOHA UMM GARN", label: "DOHA UMM GARN" },
      { value: "DOHA UMM GHUWAILINA", label: "DOHA UMM GHUWAILINA" },
      { value: "DOHA UMM LEKHBA", label: "DOHA UMM LEKHBA" },
      { value: "DOHA UMM SAID", label: "DOHA UMM SAID" },
      { value: "DOHA UMM SLAL ALI", label: "DOHA UMM SLAL ALI" },
      { value: "DOHA UMM SLAL MOHAMMAD", label: "DOHA UMM SLAL MOHAMMAD" },
      { value: "DOHA WADI AL BANAT", label: "DOHA WADI AL BANAT" },
      { value: "DOHA WESTBAY", label: "DOHA WESTBAY" },
      { value: "DOHA WUKAIR", label: "DOHA WUKAIR" },
      { value: "DUKHAN", label: "DUKHAN" },
      { value: "DUKHAN AL NASRANIYA", label: "DUKHAN AL NASRANIYA" },
      {
        value: "DUKHAN ZEKREET INTERCHANGE",
        label: "DUKHAN ZEKREET INTERCHANGE",
      },
      { value: "RAS LAFFAN", label: "RAS LAFFAN" },
      {
        value: "RAS LAFFAN INDUSTRIAL CITY",
        label: "RAS LAFFAN INDUSTRIAL CITY",
      },
      { value: "Al Adhbah", label: "Al Adhbah" },
      { value: "Al Hitmi", label: "Al Hitmi" },
      { value: "Al Jasrah", label: "Al Jasrah" },
      { value: "Al Jumaliyah", label: "Al Jumaliyah" },
      { value: "Al Daayen", label: "Al Daayen" },
      { value: "Al Ka`biyah", label: "Al Ka`biyah" },
      { value: "Al Khalifat", label: "Al Khalifat" },
    ],
    BH: [
      { value: "Capital Governorate", label: "Capital Governorate" },
      { value: "Muharraq Governorate", label: "Muharraq Governorate" },
      { value: "Northern Governorate", label: "Northern Governorate" },
      { value: "Southern Governorate", label: "Southern Governorate" },
      { value: "AALI", label: "AALI" },
      { value: "ADARI", label: "ADARI" },
      { value: "ADLIYA", label: "ADLIYA" },
      { value: "AL DUR", label: "AL DUR" },
      { value: "AL EKER", label: "AL EKER" },
      { value: "AL HAJAR", label: "AL HAJAR" },
      { value: "AL HAMALAH", label: "AL HAMALAH" },
      { value: "AL MARKH", label: "AL MARKH" },
      { value: "AL SALMANIYAH", label: "AL SALMANIYAH" },
      { value: "AL-HIDD", label: "AL-HIDD" },
      { value: "AMWAJ", label: "AMWAJ" },
      { value: "ASKAR", label: "ASKAR" },
      { value: "AWALI", label: "AWALI" },
      { value: "BARBAR", label: "BARBAR" },
      { value: "BUDAIYA", label: "BUDAIYA" },
      { value: "DIPLOMATIC AREA", label: "DIPLOMATIC AREA" },
      { value: "DURT AL BAHRAIN", label: "DURT AL BAHRAIN" },
      { value: "HAMAD TOWN", label: "HAMAD TOWN" },
      { value: "ISA TOWN", label: "ISA TOWN" },
      { value: "JANABIYAH", label: "JANABIYAH" },
      { value: "JANUSAN", label: "JANUSAN" },
      { value: "MAAMMER", label: "MAAMMER" },
      { value: "MANAMA", label: "MANAMA" },
      { value: "MUHARRAQ", label: "MUHARRAQ" },
      { value: "QALALI", label: "QALALI" },
      { value: "RIFFA", label: "RIFFA" },
      { value: "RIFFA VIEWS", label: "RIFFA VIEWS" },
      { value: "RLC", label: "RLC" },
      { value: "SAAR", label: "SAAR" },
      { value: "SAKHIR", label: "SAKHIR" },
      { value: "SALMABAD", label: "SALMABAD" },
      { value: "SANABIS", label: "SANABIS" },
      { value: "SANAD", label: "SANAD" },
      { value: "SEEF", label: "SEEF" },
      { value: "SITRA", label: "SITRA" },
      { value: "TASHAN", label: "TASHAN" },
      { value: "ZALAQ", label: "ZALAQ" },
      { value: "Dumistan", label: "Dumistan" },
      { value: "Tubli", label: "Tubli" },
      { value: "Buri", label: "Buri" },
      { value: "Diraz", label: "Diraz" },
      { value: "Bani Jamra", label: "Bani Jamra" },
      { value: "Jordab", label: "Jordab" },
      { value: "Arad", label: "Arad" },
      { value: "Samaheej", label: "Samaheej" },
      { value: "Buquwa", label: "Buquwa" },
      { value: "Jidhafs", label: "Jidhafs" },
      { value: "Abu Beham", label: "Abu Beham" },
      { value: "Abu Saiba", label: "Abu Saiba" },
      { value: "Busaiteen", label: "Busaiteen" },
      { value: "Dair", label: "Dair" },
      { value: "Dar Kulaib", label: "Dar Kulaib" },
      { value: "Ghurayfah", label: "Ghurayfah" },
      { value: "Hillat Abdulsaleh", label: "Hillat Abdulsaleh" },
      { value: "Jasra", label: "Jasra" },
      { value: "Jiblat Habshi", label: "Jiblat Habshi" },
      { value: "Jid Alhaj", label: "Jid Alhaj" },
      { value: "Juffair", label: "Juffair" },
      { value: "Karbabad", label: "Karbabad" },
      { value: "Karrana", label: "Karrana" },
      { value: "Karzakan", label: "Karzakan" },
      { value: "Khamis", label: "Khamis" },
      { value: "Malikiya", label: "Malikiya" },
      { value: "Meqsha", label: "Meqsha" },
      { value: "Mina Salman", label: "Mina Salman" },
      { value: "Muqaba", label: "Muqaba" },
      { value: "Musalla", label: "Musalla" },
      { value: "Nasfah", label: "Nasfah" },
      { value: "North Sehla", label: "North Sehla" },
      { value: "Qadam", label: "Qadam" },
      { value: "Qella", label: "Qella" },
      { value: "Qofool", label: "Qofool" },
      { value: "Qudaibiya", label: "Qudaibiya" },
      { value: "Qurayya", label: "Qurayya" },
      { value: "Ras Ruman", label: "Ras Ruman" },
      { value: "Sadad", label: "Sadad" },
      { value: "Shahrakan", label: "Shahrakan" },
      { value: "Shakhura", label: "Shakhura" },
      { value: "South Sehla", label: "South Sehla" },
      { value: "Tala Island", label: "Tala Island" },
      { value: "Um Alhassam", label: "Um Alhassam" },
      {
        value: "Umm Alsabban (Mohammadiya)",
        label: "Umm Alsabban (Mohammadiya)",
      },
      { value: "Zayed Town", label: "Zayed Town" },
      { value: "Jannusan", label: "Jannusan" },
      { value: "Almazrowiah", label: "Almazrowiah" },
      { value: "Habsab", label: "Habsab" },
    ],
    OM: [
      { value: "AL ANSAB", label: "AL ANSAB" },
      { value: "AL KHOUD", label: "AL KHOUD" },
      { value: "AL KHUWAIR", label: "AL KHUWAIR" },
      { value: "AZAIBA", label: "AZAIBA" },
      { value: "BARKA", label: "BARKA" },
      { value: "BOSHAR", label: "BOSHAR" },
      { value: "CBD AREA", label: "CBD AREA" },
      { value: "DARSAIT", label: "DARSAIT" },
      { value: "GHALA", label: "GHALA" },
      { value: "GHUBRAH", label: "GHUBRAH" },
      { value: "HAMRIYA", label: "HAMRIYA" },
      { value: "JIBROO", label: "JIBROO" },
      { value: "MABELA", label: "MABELA" },
      { value: "MADINAT AL ALAM", label: "MADINAT AL ALAM" },
      { value: "MADINAT QABOOS", label: "MADINAT QABOOS" },
      { value: "MARBAT", label: "MARBAT" },
      { value: "MEDINAT QABOOS", label: "MEDINAT QABOOS" },
      { value: "MINA AL FAHAL", label: "MINA AL FAHAL" },
      { value: "MINISTRY AREA", label: "MINISTRY AREA" },
      { value: "Muscat", label: "Muscat" },
      { value: "MUTTRAH", label: "MUTTRAH" },
      { value: "MUWALAH", label: "MUWALAH" },
      { value: "QURUM", label: "QURUM" },
      { value: "QURUM HEIGHTS", label: "QURUM HEIGHTS" },
      { value: "RAYSUT", label: "RAYSUT" },
      { value: "RUSAIL", label: "RUSAIL" },
      { value: "RUWI", label: "RUWI" },
      { value: "SALALAH", label: "SALALAH" },
      { value: "SEEB", label: "SEEB" },
      { value: "SHATTI QURUM", label: "SHATTI QURUM" },
      { value: "SOHAR", label: "SOHAR" },
      { value: "THUMRAIT", label: "THUMRAIT" },
      { value: "WADI ADAI", label: "WADI ADAI" },
      { value: "WADI KEBIR", label: "WADI KEBIR" },
      { value: "WATTAYAH", label: "WATTAYAH" },
      { value: "Sur", label: "Sur" },
      { value: "Suwaiq", label: "Suwaiq" },
      { value: "Ibri", label: "Ibri" },
      { value: "BURAIMI", label: "BURAIMI" },
      { value: "rustaq", label: "rustaq" },
      { value: "Nizwa", label: "Nizwa" },
      { value: "Khabourah", label: "Khabourah" },
      { value: "IBRA", label: "IBRA" },
      { value: "Mawaleh", label: "Mawaleh" },
      { value: "Khasab", label: "Khasab" },
      { value: "Adam", label: "Adam" },
      { value: "Al Aqir", label: "Al Aqir" },
      { value: "Al Ashkarah", label: "Al Ashkarah" },
      { value: "Al Bidayah", label: "Al Bidayah" },
      { value: "Al Duqm", label: "Al Duqm" },
      { value: "Al Ghazir", label: "Al Ghazir" },
      { value: "Al Hamra", label: "Al Hamra" },
      { value: "Al Jadida", label: "Al Jadida" },
      { value: "Al Kabil", label: "Al Kabil" },
      { value: "Al Kamil Wal Wafi", label: "Al Kamil Wal Wafi" },
      { value: "Al Mazyunah", label: "Al Mazyunah" },
      { value: "Al Mudhaibi", label: "Al Mudhaibi" },
      { value: "Al Saada", label: "Al Saada" },
      { value: "Amerat", label: "Amerat" },
      { value: "As Sulaif", label: "As Sulaif" },
      { value: "Bahla", label: "Bahla" },
      { value: "Bidbid", label: "Bidbid" },
      { value: "Bidiya", label: "Bidiya" },
      { value: "Bisiyah", label: "Bisiyah" },
      { value: "Bukha", label: "Bukha" },
      { value: "Dhalkut", label: "Dhalkut" },
      { value: "Dhank", label: "Dhank" },
      { value: "Diba", label: "Diba" },
      { value: "Dima Wattayeen", label: "Dima Wattayeen" },
      { value: "Fahud", label: "Fahud" },
      { value: "Falaj Al Qabail", label: "Falaj Al Qabail" },
      { value: "Fanja", label: "Fanja" },
      { value: "Firq", label: "Firq" },
      { value: "Haima", label: "Haima" },
      { value: "Izki", label: "Izki" },
      { value: "Jaalan Bani Bu Ali", label: "Jaalan Bani Bu Ali" },
      { value: "Jaalan ni Bu Hassan", label: "Jaalan ni Bu Hassan" },
      { value: "Jabal Akhdar", label: "Jabal Akhdar" },
      { value: "Liwa", label: "Liwa" },
      { value: "Madha", label: "Madha" },
      { value: "Mahdha", label: "Mahdha" },
      { value: "Mahout", label: "Mahout" },
      { value: "Manah", label: "Manah" },
      { value: "Marmul", label: "Marmul" },
      { value: "Masirah", label: "Masirah" },
      { value: "Musannah", label: "Musannah" },
      { value: "Nakhl", label: "Nakhl" },
      { value: "Qatana", label: "Qatana" },
      { value: "Qurayat", label: "Qurayat" },
      { value: "Sadah", label: "Sadah" },
      { value: "Saham", label: "Saham" },
      { value: "Samad Al Shan", label: "Samad Al Shan" },
      { value: "Samayil", label: "Samayil" },
      { value: "Shinas", label: "Shinas" },
      { value: "Sidab", label: "Sidab" },
      { value: "Sinaw", label: "Sinaw" },
      { value: "Sunaina", label: "Sunaina" },
      { value: "Tiwi", label: "Tiwi" },
      { value: "Wadi al Maawil", label: "Wadi al Maawil" },
      { value: "Wadi Bani Khalid", label: "Wadi Bani Khalid" },
    ],
    AE: [
      { value: "Dubai", label: "Dubai" },
      { value: "ABU DHABI CITY", label: "ABU DHABI CITY" },
      { value: "ABU HAIL", label: "ABU HAIL" },
      { value: "AFLAJ AL AIN", label: "AFLAJ AL AIN" },
      { value: "AJMAN CITY", label: "AJMAN CITY" },
      { value: "AL AAHAD", label: "AL AAHAD" },
      { value: "AL ABRAQ", label: "AL ABRAQ" },
      { value: "AL AIN AIRPORT", label: "AL AIN AIRPORT" },
      { value: "AL AIN", label: "AL AIN" },
      { value: "AL AIN DAIRY FARM AL AIN", label: "AL AIN DAIRY FARM AL AIN" },
      { value: "AL ANDALUS COMPOUND", label: "AL ANDALUS COMPOUND" },
      { value: "AL AQAA", label: "AL AQAA" },
      { value: "AL ATTAIN", label: "AL ATTAIN" },
      { value: "AL AWEER", label: "AL AWEER" },
      { value: "AL BARARI", label: "AL BARARI" },
      { value: "AL BARASHI", label: "AL BARASHI" },
      { value: "AL BARSHA", label: "AL BARSHA" },
      { value: "AL BARSHA SOUTH", label: "AL BARSHA SOUTH" },
      { value: "AL BASRA AL AIN", label: "AL BASRA AL AIN" },
      { value: "AL BURAIRAT", label: "AL BURAIRAT" },
      { value: "AL DAR AL BAIDA", label: "AL DAR AL BAIDA" },
      { value: "AL DHAFRA", label: "AL DHAFRA" },
      { value: "AL DHAFRA AB", label: "AL DHAFRA AB" },
      { value: "AL DHAFRA AIR BASE AE", label: "AL DHAFRA AIR BASE AE" },
      { value: "AL DHAIT", label: "AL DHAIT" },
      { value: "Dhaid", label: "Dhaid" },
      { value: "AL DHAIT NORTH", label: "AL DHAIT NORTH" },
      { value: "AL DHAIT SOUTH", label: "AL DHAIT SOUTH" },
      { value: "AL DIGDAGA", label: "AL DIGDAGA" },
      { value: "AL DIYAFA", label: "AL DIYAFA" },
      { value: "AL FALLAH", label: "AL FALLAH" },
      { value: "AL FURJAN", label: "AL FURJAN" },
      { value: "AL GARHOUD", label: "AL GARHOUD" },
      { value: "AL GHAIL INDUSTRIAL PARK", label: "AL GHAIL INDUSTRIAL PARK" },
      { value: "AL GHUSAIS", label: "AL GHUSAIS" },
      {
        value: "AL GHUSAIS INDUSTRIAL AREA",
        label: "AL GHUSAIS INDUSTRIAL AREA",
      },
      { value: "AL HADITHA", label: "AL HADITHA" },
      { value: "AL HAIL INDISTRIAL AREA", label: "AL HAIL INDISTRIAL AREA" },
      { value: "AL HAWIYAH", label: "AL HAWIYAH" },
      { value: "AL HAZZAN", label: "AL HAZZAN" },
      { value: "AL HILI AL AIN", label: "AL HILI AL AIN" },
      { value: "AL HUDAIBAH", label: "AL HUDAIBAH" },
      { value: "AL HUMRAH", label: "AL HUMRAH" },
      { value: "AL JADDAF", label: "AL JADDAF" },
      { value: "AL JAHLI AL AIN", label: "AL JAHLI AL AIN" },
      { value: "AL JIMI AL AIN", label: "AL JIMI AL AIN" },
      { value: "AL KHABISI AL AIN", label: "AL KHABISI AL AIN" },
      { value: "AL KHARRAN", label: "AL KHARRAN" },
      { value: "AL KHATEM", label: "AL KHATEM" },
      { value: "AL KHAWANEEJ", label: "AL KHAWANEEJ" },
      { value: "AL KHOR", label: "AL KHOR" },
      { value: "AL KHUBASI", label: "AL KHUBASI" },
      { value: "AL KHUZAM", label: "AL KHUZAM" },
      { value: "AL KUWAITAT AL AIN", label: "AL KUWAITAT AL AIN" },
      { value: "AL LAYAN", label: "AL LAYAN" },
      { value: "AL MADAR", label: "AL MADAR" },
      { value: "AL MAIDEN", label: "AL MAIDEN" },
      { value: "AL MAIRID", label: "AL MAIRID" },
      { value: "AL MAMOURAH", label: "AL MAMOURAH" },
      { value: "AL MANASEER AL AIN", label: "AL MANASEER AL AIN" },
      {
        value: "AL MARKAZ IND. PARK ABU DHABI",
        label: "AL MARKAZ IND. PARK ABU DHABI",
      },
      { value: "AL MARYAH ISLAND", label: "AL MARYAH ISLAND" },
      { value: "AL MISBAH AL AIN", label: "AL MISBAH AL AIN" },
      { value: "AL MNAIZFAH AL AIN", label: "AL MNAIZFAH AL AIN" },
      { value: "AL MNAIZLAH AL AIN", label: "AL MNAIZLAH AL AIN" },
      { value: "AL MURABBA AL AIN", label: "AL MURABBA AL AIN" },
      { value: "AL MUTAWAA AL AIN", label: "AL MUTAWAA AL AIN" },
      { value: "AL MUWAIJI AL AIN", label: "AL MUWAIJI AL AIN" },
      { value: "AL NAHDA 1", label: "AL NAHDA 1" },
      { value: "AL NAKEEL", label: "AL NAKEEL" },
      { value: "AL NAKHEEL", label: "AL NAKHEEL" },
      { value: "AL OWAINAH AL AIN", label: "AL OWAINAH AL AIN" },
      { value: "AL QUAWASIM CORNICHE", label: "AL QUAWASIM CORNICHE" },
      { value: "AL QUOZ", label: "AL QUOZ" },
      { value: "AL RAHA", label: "AL RAHA" },
      { value: "AL RAHA BEACH AREA", label: "AL RAHA BEACH AREA" },
      { value: "AL RAHMANIYA", label: "AL RAHMANIYA" },
      { value: "AL RAMLAH", label: "AL RAMLAH" },
      { value: "AL RAMS", label: "AL RAMS" },
      { value: "AL RAS", label: "AL RAS" },
      { value: "AL RAUDAH", label: "AL RAUDAH" },
      { value: "AL RIGGA", label: "AL RIGGA" },
      { value: "AL RIQQAH", label: "AL RIQQAH" },
      { value: "AL RUMAILAH AL AIN", label: "AL RUMAILAH AL AIN" },
      { value: "AL RUWAIKAH AL AIN", label: "AL RUWAIKAH AL AIN" },
      { value: "AL SAJJA", label: "AL SAJJA" },
      { value: "AL SALAMAH", label: "AL SALAMAH" },
      { value: "AL SANAIYA AL AIN", label: "AL SANAIYA AL AIN" },
      { value: "AL SAROOJ AL AIN", label: "AL SAROOJ AL AIN" },
      { value: "AL SHAREJ AL AIN", label: "AL SHAREJ AL AIN" },
      { value: "AL SILA", label: "AL SILA" },
      { value: "AL SOFOUH GARDENS", label: "AL SOFOUH GARDENS" },
      { value: "AL TAWAM AL AIN", label: "AL TAWAM AL AIN" },
      { value: "AL TOWAYYA AL AIN", label: "AL TOWAYYA AL AIN" },
      { value: "AL TWAR", label: "AL TWAR" },
      { value: "AL URAIBI", label: "AL URAIBI" },
      { value: "AL WAHA", label: "AL WAHA" },
    ],
    KW: [
      { value: "Abbasiya", label: "Abbasiya" },
      { value: "Abdally", label: "Abdally" },
      { value: "Abdulla Al Salem", label: "Abdulla Al Salem" },
      { value: "Abdullah Mubarak", label: "Abdullah Mubarak" },
      { value: "Abrak Kheitan", label: "Abrak Kheitan" },
      { value: "Abu Al Hasania", label: "Abu Al Hasania" },
      { value: "Abu Fatira", label: "Abu Fatira" },
      { value: "Abu Halifa", label: "Abu Halifa" },
      { value: "Adan", label: "Adan" },
      { value: "Adeliya", label: "Adeliya" },
      { value: "Ahmadi", label: "Ahmadi" },
      { value: "Ahmed Al Jaber Airbase", label: "Ahmed Al Jaber Airbase" },
      { value: "Al Abadaly", label: "Al Abadaly" },
      { value: "Al Ayoun", label: "Al Ayoun" },
      { value: "Al Bidaa", label: "Al Bidaa" },
      { value: "Al Funnayhil", label: "Al Funnayhil" },
      { value: "Al Masayeel", label: "Al Masayeel" },
      { value: "Al Naseem", label: "Al Naseem" },
      { value: "Al Qasar", label: "Al Qasar" },
      { value: "Al Rai", label: "Al Rai" },
      { value: "Al Taima", label: "Al Taima" },
      { value: "Al Waha", label: "Al Waha" },
      { value: "Ali Al Salem Air Base", label: "Ali Al Salem Air Base" },
      { value: "Ali Sabah Al Salem", label: "Ali Sabah Al Salem" },
      { value: "Amghara", label: "Amghara" },
      { value: "Andalus", label: "Andalus" },
      { value: "Ardiya", label: "Ardiya" },
      { value: "Ashwaq Qurain", label: "Ashwaq Qurain" },
      { value: "Bayan", label: "Bayan" },
      { value: "Bneid Al Gar", label: "Bneid Al Gar" },
      { value: "Camp Arifjan", label: "Camp Arifjan" },
      { value: "Camp Buehring", label: "Camp Buehring" },
      { value: "Daher", label: "Daher" },
      { value: "Daiya", label: "Daiya" },
      { value: "Dajeej", label: "Dajeej" },
      { value: "Darwaza", label: "Darwaza" },
      { value: "Dasma", label: "Dasma" },
      { value: "Dasman", label: "Dasman" },
      { value: "Doha", label: "Doha" },
      { value: "Egaila", label: "Egaila" },
      { value: "Fahad Al Ahmad", label: "Fahad Al Ahmad" },
      { value: "Fahaheel", label: "Fahaheel" },
      { value: "Faiha", label: "Faiha" },
      { value: "Farwaniya", label: "Farwaniya" },
      { value: "Fintas", label: "Fintas" },
      { value: "Firdous", label: "Firdous" },
      { value: "Funantees", label: "Funantees" },
      { value: "Garnata", label: "Garnata" },
      { value: "Hadiya", label: "Hadiya" },
      { value: "Hawally", label: "Hawally" },
      { value: "Hitteen", label: "Hitteen" },
      { value: "Ishbilya", label: "Ishbilya" },
      { value: "Jaber Al Ahmad", label: "Jaber Al Ahmad" },
      { value: "Jaber Al Ali", label: "Jaber Al Ali" },
      { value: "Jabriya", label: "Jabriya" },
      { value: "Jahra", label: "Jahra" },
      { value: "Jleeb Al Shuyoukh", label: "Jleeb Al Shuyoukh" },
      { value: "Juliah", label: "Juliah" },
      { value: "Kabd", label: "Kabd" },
      { value: "Keifan", label: "Keifan" },
      { value: "Khaldiya", label: "Khaldiya" },
      { value: "Kheiran", label: "Kheiran" },
      { value: "Kuwait City", label: "Kuwait City" },
      { value: "Mahboula", label: "Mahboula" },
      { value: "Maidan Hawally", label: "Maidan Hawally" },
      { value: "Maliya", label: "Maliya" },
      { value: "Mangaf", label: "Mangaf" },
      { value: "Mansouriya", label: "Mansouriya" },
      { value: "Messila", label: "Messila" },
      { value: "Mina Abdulla", label: "Mina Abdulla" },
      { value: "Mina Ahmadi", label: "Mina Ahmadi" },
      { value: "Mina Al Zour", label: "Mina Al Zour" },
      { value: "Mirgab", label: "Mirgab" },
      { value: "Mishref", label: "Mishref" },
      { value: "Mubarak Abdullah", label: "Mubarak Abdullah" },
      { value: "Mubarak Aj Jabar Suburb", label: "Mubarak Aj Jabar Suburb" },
      { value: "Mubarak Al Kabeer", label: "Mubarak Al Kabeer" },
      { value: "Mubarakiya", label: "Mubarakiya" },
      { value: "Nahda", label: "Nahda" },
      { value: "Nigra", label: "Nigra" },
      { value: "Nuzha", label: "Nuzha" },
      { value: "Omariya", label: "Omariya" },
      { value: "Qadsiya", label: "Qadsiya" },
      { value: "Qairawan", label: "Qairawan" },
      { value: "Qibla", label: "Qibla" },
      { value: "Qortuba", label: "Qortuba" },
      { value: "Qosoor", label: "Qosoor" },
      { value: "Qurain", label: "Qurain" },
      { value: "Rabiya", label: "Rabiya" },
      { value: "Rawdah", label: "Rawdah" },
      { value: "Rehab", label: "Rehab" },
      { value: "Rikka", label: "Rikka" },
      { value: "Rumaithiya", label: "Rumaithiya" },
      { value: "Saad Al Abdullah", label: "Saad Al Abdullah" },
      { value: "Sabah Al Ahmed", label: "Sabah Al Ahmed" },
      { value: "Sabah Al Naser", label: "Sabah Al Naser" },
      { value: "Sabah Al Salem", label: "Sabah Al Salem" },
      { value: "Sabahiya", label: "Sabahiya" },
      { value: "Sabhan", label: "Sabhan" },
      { value: "Sabiya", label: "Sabiya" }
    ],
    
  };

  const [postalCode, setPostalCode] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [lastName, setLastName] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [address, setAddress] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [district, setDistrict] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [apartment, setApartment] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [firstName, setFirstName] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });
  const [email, setEmail] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });


  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  useEffect(() => {
    handleGetCart();
    handleGetUser();
    getShippmentInfo();
  }, []);

  async function handleSubmitMobile() {
    setLoading(true);
    navigate(`/cart/${cart.cart_id}`);
    setLoading(false);
  }
  async function getShippmentInfo() {
    setLoading(true);
    const saudiArabia = Country.getCountryByCode("SA");
    const oman = Country.getCountryByCode("OM");
    const uae = Country.getCountryByCode("AE");
    const qatar = Country.getCountryByCode("QA");
    const bahrain = Country.getCountryByCode("BH");
    const kuwait=Country.getCountryByCode("KW");

    const countryOptions = [
      { value: saudiArabia.isoCode, label: saudiArabia.name },
      { value: oman.isoCode, label: oman.name },
      { value: uae.isoCode, label: uae.name },
      { value: qatar.isoCode, label: qatar.name },
      { value: bahrain.isoCode, label: bahrain.name },
      { value: kuwait.isoCode, label: kuwait.name } ,
    ];
    setCountryOptions(countryOptions);

    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    } else {
      let guestSessionId = localStorage.getItem("guestId");
      if (guestSessionId === null) {
        guestSessionId = generateGuestSessionId();
        localStorage.setItem("guestId", guestSessionId);
      }
      token = guestSessionId;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/getShipmentInfo`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      console.log(responseData);

      if (
        responseData.error ===
          "You dont have cart yet to fill out shipmet info." ||
        responseData.error === "You dont have shipmet info."
      ) {
      } else {
        setEmail({
          value: responseData.email,
          isTouched: true,
          isValid: true,
        });
        setFirstName({
          isTouched: true,
          value: responseData.first_name,
          isValid: true,
        });
        setPostalCode({
          isTouched: false,
          value: responseData.postal_code,
        });
        setLastName({
          isTouched: true,
          value: responseData.last_name,
          isValid: true,
        });
        setAddress({
          isTouched: true,
          value: responseData.address,
          isValid: true,
        });
        setApartment({
          isTouched: true,
          value: responseData.appartment,
          isValid: true,
        });
        setDistrict({
          isTouched: true,
          value: responseData.district,
          isValid: true,
        });
        setPhone(
          responseData.phone,
        );
        setIsPhoneValid(isValidPhoneNumber(responseData.phone));
       
        setReceiveEmailsChecked(responseData.news_offers_flag === 1);
        setSaveAddress(responseData.save_address_flag === 1);

        const selectedCountryFromResponse = countryOptions.find(
          (country) => country.value === responseData.country
        );

        if (selectedCountryFromResponse) {
          // Step 1: Set the selected country
          setSelectedCountry(selectedCountryFromResponse);

          // Step 2: Filter cities based on selected country
          const filteredCities =
            cityOptionsByCountry[selectedCountryFromResponse.value] || [];

          // Step 3: Update city options based on the selected country
          setCitySelectedOptions(filteredCities);

          // Step 4: Find the city in the filtered cities and set it as selected
          const selectedCityFromResponse = filteredCities.find(
            (city) => city.label === responseData.city
          );

          if (selectedCityFromResponse) {
            setSelectedCity(selectedCityFromResponse);
          }
        }
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  }
  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    } else {
      let guestSessionId = localStorage.getItem("guestId");
      if (guestSessionId === null) {
        guestSessionId = generateGuestSessionId();
        localStorage.setItem("guestId", guestSessionId);
      }
      token = guestSessionId;
    }

    const formData = {
      first_name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      phone: phone,
      address: address.value,
      country: selectedCountry.value,
      district: district.value,
      city: selectedCity.label,
      postal_code: postalCode.value,
      appartment: apartment.value,
      news_offers_flag: receiveEmailsChecked ? 1 : 0,
      save_address_flag: saveAddress ? 1 : 0,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/addShipmentInfo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData), // Stringify the JSON object
        }
      );
      const responseData = await response.json();

      if (response.ok) {
        navigate(`/cart/${cart.cart_id}/shipping`);
      } else {
        // Handle server errors
        console.error("Server error:", responseData.error);
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      // Handle network errors
      console.error("Network error:", error.message);
      setLoading(false);
      setError(true);
    }
  }
  const handleGetUser = () => {
    const user = User.fromLocalStorage();

    if (user) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  };
  const generateGuestSessionId = () => {
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
    return `guest_${randomNumber}`;
  };
  const removeProductFromCart = async (productId) => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/removeProductFromCart?product_id=${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setLoading(true);
        handleGetCart();
        fetchCartCount();
      }
    } catch (error) {
      console.error("Error while removing product from cart", error);
    }
  };
  const editProductInCart = async (productId, quantity) => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/editCart?product_id=${productId}&quantities=${quantity}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        await handleGetCart();
      } else {
        console.error("Error updating product quantity:", response.statusText);
      }

      return responseData; // Return the response data
    } catch (error) {
      console.error("Error updating product quantity:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleGetCart = async () => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/viewCart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();
      if (responseData.message === "Cart is empty") {
        setCartEmpty(true);
        // navigate(`/cart`);
      } else {
        setSubtotal(responseData.sub_total);
        setProducts(responseData.cart);

        setCart(responseData);
      }
    } catch (error) {
      console.error("Error while viewing cart", error);
    } finally {
      setLoading(false);
    }
  };
  function handleEmailInput(e) {
    setEmail({
      isTouched: true,
      value: e.target.value,
      isValid: validateEmail(e.target.value),
    });
  }
  function handleFirstNameInput(e) {
    let firstName = e.target.value;

    // Capitalize the first letter
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

    // Allow English, Arabic characters, and spaces
    const isValid =
      /^[\u0600-\u06FFa-zA-Z\s]+$/.test(firstName) && firstName.length > 3;

    setFirstName({
      isTouched: true,
      value: firstName,
      isValid: isValid,
    });
  }
  function handleLastNameInput(e) {
    let lastName = e.target.value;

    // Capitalize the first letter
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

    // Allow English, Arabic characters, and spaces
    const isValid =
      /^[\u0600-\u06FFa-zA-Z\s]+$/.test(lastName) && lastName.length > 3;

    setLastName({
      isTouched: true,
      value: lastName,
      isValid: isValid,
    });
  }
  function handleAddressInput(e) {
    let address = e.target.value;

    // Capitalize the first letter
    address = address.charAt(0).toUpperCase() + address.slice(1);

    // Allow any characters (including Arabic and white spaces) and minimum length of 10 characters
    const isValid = /^[\u0600-\u06FF\s\S]{10,}$/u.test(address); // Match any character (including Arabic and white spaces)

    setAddress({
      isTouched: true,
      value: address,
      isValid: isValid,
    });
  }
  function handleDistrictInput(e) {
    let district = e.target.value;

    district = district.charAt(0).toUpperCase() + district.slice(1);

   
    setDistrict({
      isTouched: true,
      value: district,
      isValid: true,
    });
  }

  function handleApartmentInput(e) {
    let apartment = e.target.value;

    // Capitalize the first letter
    apartment = apartment.charAt(0).toUpperCase() + apartment.slice(1);

    // Allow any characters (including Arabic and white spaces) and minimum length of 3 characters
    const isValid = /^[\u0600-\u06FF\s\S]{1,}$/u.test(apartment); // Match any character (including Arabic and white spaces)

    setApartment({
      isTouched: true,
      value: apartment,
      isValid: isValid,
    });
  }

  function handlePostalCodeInput(e) {
    // Convert Arabic numerals to Western numerals
    const convertToWesternNumbers = (str) => {
      const arabicToWestern = {
        "٠": "0",
        "١": "1",
        "٢": "2",
        "٣": "3",
        "٤": "4",
        "٥": "5",
        "٦": "6",
        "٧": "7",
        "٨": "8",
        "٩": "9",
      };
      return str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (match) => arabicToWestern[match]);
    };

    let postalCode = e.target.value;

    // Convert Arabic numbers to Western numbers
    postalCode = convertToWesternNumbers(postalCode);

    // Remove any non-digit characters from the input
    postalCode = postalCode.replace(/\D/g, "");

    // Check if the postal code is valid for Saudi Arabia (5 digits)
    const isValid = /^\d{5}$/.test(postalCode);

    setPostalCode({
      isTouched: true,
      value: postalCode,
      isValid: isValid,
    });
  }

  function handleReceiveEmailsChange() {
    setReceiveEmailsChecked(!receiveEmailsChecked);
  }
  function handleSaveAddressChange() {
    setSaveAddress(!saveAddress);
  }
  const handlePhoneChange = (phone) => {

    // Convert Arabic numerals to Western numerals
    const convertToWesternNumbers = (str) => {
      const arabicToWestern = {
        "٠": "0",
        "١": "1",
        "٢": "2",
        "٣": "3",
        "٤": "4",
        "٥": "5",
        "٦": "6",
        "٧": "7",
        "٨": "8",
        "٩": "9",
      };
      return str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (match) => arabicToWestern[match]);
    };

    const value = phone;
    const westernValue = convertToWesternNumbers(value);

  
    setPhone(westernValue);
    setIsPhoneValid(isValidPhoneNumber(phone));
  };
  const handlePhoneFocus = () => {
    setIsPhoneTouched(true);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);

    // Get the cities for the selected country
    const filteredCities = cityOptionsByCountry[selectedOption.value] || [];

    // Set the city options based on the selected country
    setCitySelectedOptions(filteredCities);

    // Reset selected city and state
    setSelectedCity(null);
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img
          src={downArrow}
          alt="Right Arrow"
          style={{ width: 20, height: 20 }}
        />
      </components.DropdownIndicator>
    );
  };

  const formIsValid =
    firstName.isValid &&
    lastName.isValid &&
    address.isValid &&
    (!authenticated ? email.isValid : true) &&
    isValidPhoneNumber(phone)&&
    apartment.isValid &&
    selectedCountry !== null &&
    selectedCity !== null;

  return (
    <main>
      <div className="mobile-cart">
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loader-overlay">
              <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
            </div>
          </div>
        ) : (
          <>
            <div className="cart-container">
              <div className="my-cart">{t("myCart")}</div>
              {cartEmpty ? (
                <div className="empty-cart">
                  <div className="empty-cart-message">
                    {t("yourCartIsEmpty")}&nbsp;{" "}
                    <Link
                      to="/products"
                      className="empty-cart-message-underline"
                    >
                      {" "}
                      {t("continueShopping")}{" "}
                    </Link>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    {products &&
                      products.length > 0 &&
                      products.map((product, index) => (
                        <div className="single-product-coloum" key={index}>
                          <div className="single-product-cart">
                            {product.medias && product.medias.length > 0 && (
                              <img
                                src={
                                  product.medias.find(
                                    (media) => media.tag === "rectangle"
                                  ).image_url
                                }
                                alt={product.tag}
                                height={130}
                              />
                            )}

                            <div
                              className={`single-coloum ${
                                isArabic ? "arabic" : ""
                              }`}
                            >
                              <div
                                className="product-name"
                                style={{ display: "block" }}
                              >
                                {isArabic ? product.name_ar : product.name_en}
                              </div>
                              <div className="product-price">
                                {product.status === "Sale"
                                  ? product.discountedPrice
                                  : product.price}{" "}
                                {t("SAR")}
                              </div>
                              <div className="product-conter">
                                <CounterBox
                                  ProductColor={"rgb(252, 219, 204)"}
                                  quantity={product.quantity}
                                  productId={product.id}
                                  productLimit={product.product_limit}
                                  onUpdate={(productId, quantity) =>
                                    editProductInCart(productId, quantity)
                                  }
                                />
                                <div
                                  className="product-remove"
                                  onClick={() =>
                                    removeProductFromCart(product.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("remove")}
                                </div>
                              </div>
                            </div>
                          </div>
                          {index < products.length - 1 && (
                            <img
                              src={BlackLine}
                              alt="blackLine"
                              className="blackLine"
                            />
                          )}
                        </div>
                      ))}
                  </div>
                </>
              )}
            </div>
            {/* Include checkout-container inside the conditional rendering block */}
            {!cartEmpty && (
              <div className="checkout-container">
                <div className="subtotal-price">
                  <div className="subtotal-text">{t("subtotal")}</div>
                  <div className="price-text">
                    {subtotal} {t("SAR")}
                  </div>
                </div>
                <div className="button-cart-container">
                  <Button
                    className="shipping-button-cart-mobile"
                    text={t("checkOut")}
                    onClick={handleSubmitMobile}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="pc-cart">
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loader-overlay">
              <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
            </div>
          </div>
        ) : (
          <>
            {cartEmpty ? (
              <div className="empty-cart">
                <div className="empty-cart-message">
                  {t("yourCartIsEmpty")}&nbsp;{" "}
                  <Link to="/products" className="empty-cart-message-underline">
                    {" "}
                    {t("continueShopping")}{" "}
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <div className="Left-Align-cart">
                  {!authenticated && (
                    <div className="header-cart-pc">
                      <div className="contact-title-cart-pc">
                        {t("contact")}
                      </div>

                      <div className="signUp-logIn-cart-pc">
                        {t("haveAnAccount")}{" "}
                        <a href="/login">
                          <span className="underline-text-cart-pc">
                            {t("logIn")}
                          </span>
                        </a>
                      </div>
                    </div>
                  )}

                  <div className="order-Information-cart-pc">
                    {!authenticated && (
                      <div style={{ color: "rgb(129,129,129)" }}>
                        <Input
                          name="email"
                          type="text"
                          onChange={handleEmailInput}
                          isValid={email.isValid}
                          isTouched={email.isTouched}
                          placeholder={t("Email")}
                          value={email.value}
                          errorMsg={t("Enter a valid email")}
                        />

                        <div className="receive-emails-cart-pc">
                          <Checkbox
                            className="checkbox-rose-cart-pc"
                            checked={receiveEmailsChecked}
                            onChange={handleReceiveEmailsChange}
                            size="medium"
                            sx={{
                              color: "rgba(252, 219, 204, 1)",
                              fontSize: 1,
                              "&.Mui-checked": {
                                color: "rgba(252, 219, 204, 1)",
                                fontSize: 1,
                              },
                            }}
                          />
                          {t("notifyWithOffers")}
                        </div>
                      </div>
                    )}

                    <div className="shipping-title-cart-pc">
                      {t("shippingAddress")}{" "}
                    </div>

                    <div className="sign-up-info-cart-pc">
                      <Input
                        name="firstName"
                        type="text"
                        onChange={handleFirstNameInput}
                        isValid={firstName.isValid}
                        isTouched={firstName.isTouched}
                        placeholder={t("First Name")}
                        value={firstName.value}
                        errorMsg={t("Enter a valid first name")}
                      />
                      <Input
                        name="lastName"
                        type="text"
                        onChange={handleLastNameInput}
                        isValid={lastName.isValid}
                        isTouched={lastName.isTouched}
                        placeholder={t("Last Name")}
                        value={lastName.value}
                        errorMsg={t("Enter a valid last name")}
                      />

                      <div className="selector-cart-pc">
                        <Select
                          className="delect-cart-pc"
                          options={countryOptions}
                          onChange={handleCountryChange}
                          value={selectedCountry}
                          placeholder={t("selectCountry")}
                          components={{ DropdownIndicator }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              border: "none",
                              width: "100%", // Set custom width
                              display: "flex",
                              alignItems: "center",
                              borderColor: state.isFocused
                                ? "transparent"
                                : provided.borderColor, // Remove border color on focus
                              boxShadow: state.isFocused
                                ? "none"
                                : provided.boxShadow,
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "rgb(129,129,129)", // Change the color of the dropdown indicator
                              marginLeft: "auto", // Move the dropdown indicator to the end
                              strokeWidth: 1, // Adjust the thickness of the arrow
                            }),
                            indicatorSeparator: () => ({
                              display: "none", // Hide the separator between dropdown indicator and the select
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "rgb(255,255,255)", // Set transparent background for dropdown menu
                              border: "none", // Remove border around the dropdown
                              marginTop: 0, // Remove default margin
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "your_selected_color"
                                : "transparent", // Change background color for selected option
                              color: "your_text_color", // Change text color for options
                              border: "none",
                              "&:hover": {
                                backgroundColor: "transparent", // Change background color on hover
                              },
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "your_text_color", // Change text color for single value
                              marginLeft: "2%",
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "rgba(129, 129, 129, 1)",
                              marginLeft: "2%", // Change color of placeholder text
                            }),
                          }}
                        />
                      </div>

                      <div className="selector-cart-pc">
                        <Select
                          className="delect-cart-pc"
                          options={citySelectedOptions}
                          onChange={handleCityChange}
                          value={selectedCity}
                          placeholder={t("selectCity")}
                          isDisabled={!selectedCountry}
                          s
                          components={{ DropdownIndicator }}
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "transparent",
                              border: "none",
                              width: "100%", // Set custom width
                              display: "flex",
                              alignItems: "center",
                              borderColor: state.isFocused
                                ? "transparent"
                                : provided.borderColor, // Remove border color on focus
                              boxShadow: state.isFocused
                                ? "none"
                                : provided.boxShadow,
                            }),
                            dropdownIndicator: (provided) => ({
                              ...provided,
                              color: "rgb(129,129,129)", // Change the color of the dropdown indicator
                              marginLeft: "auto", // Move the dropdown indicator to the end
                            }),
                            indicatorSeparator: () => ({
                              display: "none", // Hide the separator between dropdown indicator and the select
                            }),
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "rgb(255,255,255)", // Set transparent background for dropdown menu
                              border: "none",
                              marginTop: 0, // Remove default margin
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isSelected
                                ? "your_selected_color"
                                : "transparent", // Change background color for selected option
                              color: "your_text_color",
                              // Change text color for options
                              "&:hover": {
                                backgroundColor: "transparent", // Change background color on hover
                              },
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: "your_text_color",
                              marginLeft: "2%", // Change text color for single value
                            }),
                            placeholder: (provided) => ({
                              ...provided,
                              color: "rgba(129, 129, 129, 1)",
                              marginLeft: "2%", // Change color of placeholder text
                            }),
                          }}
                        />
                      </div>

                      <Input
                        name="district"
                        type="text"
                        onChange={handleDistrictInput}
                        isValid={district.isValid}
                        isTouched={district.isTouched}
                        placeholder={t("district")}
                        value={district.value}
                        errorMsg={t("enterValidDistrict")}
                      />

                      <Input
                        name="address"
                        type="text"
                        onChange={handleAddressInput}
                        isValid={address.isValid}
                        isTouched={address.isTouched}
                        placeholder={t("address")}
                        value={address.value}
                        errorMsg={t("enterValidAddress")}
                      />

                      <Input
                        name="apartment"
                        type="text"
                        onChange={handleApartmentInput}
                        isValid={apartment.isValid}
                        isTouched={apartment.isTouched}
                        placeholder={t("appartment")}
                        value={apartment.value}
                        errorMsg={t("enterValidAppartment")}
                      />
                      <Input
                        name="postalCode"
                        type="text"
                        onChange={handlePostalCodeInput}
                        isValid={postalCode.isValid}
                        isTouched={postalCode.isTouched}
                        placeholder={t("postalCode")}
                        value={postalCode.value}
                        errorMsg={t("enterValidPostalCode")}
                      />
                      <div>
                        <div className="phone-input-wrapper">
                          <PhoneInput
                            defaultCountry="sa"
                            value={phone}
                            onFocus={handlePhoneFocus}
                            onChange={handlePhoneChange}
                            className={`phone-input ${
                              !isPhoneValid ? "error" : "valid"
                            }`}
                          />
                          <div className="validation-icon">{isPhoneTouched && (!isPhoneValid ? <ErrorIcon /> : <Valid />)}
                            
                          </div>
                        </div>

                        {!isPhoneValid && (
                          <p className="input-error">
                            {isPhoneTouched&&(t("valid_phone_error"))}
                          </p>
                        )}
                      </div>
                    </div>
                    {authenticated && (
                      <div className="save-info-cart-pc">
                        <Checkbox
                          className="checkbox-rose-cart-pc"
                          checked={saveAddress}
                          onChange={handleSaveAddressChange}
                          size="medium"
                          sx={{
                            color: "rgba(252, 219, 204, 1)",
                            fontSize: 1,
                            "&.Mui-checked": {
                              color: "rgba(252, 219, 204, 1)",
                              fontSize: 1,
                            },
                          }}
                        />
                        {t("saveInfo")}
                      </div>
                    )}

                    <Link to={"/cart"}>
                      <div
                        className={`return-shopping-pc ${
                          isArabic ? "arabic" : ""
                        }`}
                      >
                        {t("continueShopping")}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="Right-Align-cart">
                  <div>
                    {products &&
                      products.length > 0 &&
                      products.map((product, index) => (
                        <div className="single-product-coloum" key={index}>
                          <div className="single-product-cart">
                            {product.medias && product.medias.length > 0 && (
                              <img
                                src={
                                  product.medias.find(
                                    (media) => media.tag === "rectangle"
                                  ).image_url
                                }
                                alt={product.tag}
                                height={130}
                              />
                            )}

                            <div
                              className={`single-coloum ${
                                isArabic ? "arabic" : ""
                              }`}
                            >
                              <div className="product-name">
                                {isArabic ? product.name_ar : product.name_en}
                              </div>
                              <div className="product-price">
                                {product.status === "Sale"
                                  ? product.discountedPrice
                                  : product.price}{" "}
                                {t("SAR")}
                              </div>

                              <div className="product-conter">
                                <CounterBox
                                  ProductColor={"rgb(252, 219, 204)"}
                                  quantity={product.quantity}
                                  productId={product.id}
                                  productLimit={product.product_limit}
                                  onUpdate={(productId, quantity) =>
                                    editProductInCart(productId, quantity)
                                  }
                                />
                                <div
                                  className="product-remove"
                                  onClick={() =>
                                    removeProductFromCart(product.id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("remove")}
                                </div>
                              </div>
                            </div>
                          </div>
                          {index < products.length && (
                            <img
                              src={BlackLine}
                              alt="blackLine"
                              className="blackLine"
                            />
                          )}
                        </div>
                      ))}
                    <div className="subtotal-container-pc">
                      {!cartEmpty && (
                        <>
                          <div className="row-pc">
                            <div className="title-pc">{t("subtotal")}</div>
                            <div className="value-pc">
                              {subtotal} {t("SAR")}
                            </div>
                          </div>

                          <div className="row-pc">
                            <div className="title-pc">{t("shippingFees")}</div>
                            <div className="value-pc">-</div>
                          </div>
                          <div className="row-pc">
                            <div className="title-pc">{t("total")}</div>
                            <div className="value-pc">
                              {subtotal} {t("SAR")}
                            </div>
                          </div>
                        </>
                      )}
                      <img
                        src={BlackLine}
                        alt="blackLine"
                        className="blackLine"
                      />
                    </div>
                  </div>
                  <div className="button-cart-container">
                    <Button
                      className="shipping-button-cart-pc"
                      text={t("checkOut")}
                      onClick={handleSubmit}
                      disabled={!formIsValid}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Footer />
    </main>
  );
};

export default Cart;
