import React, { useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SyncLoader from "react-spinners/SyncLoader";
import { useTranslation } from "react-i18next";

import "../../styles/verification.css";
import Lock from "../../assets/lock-screen.svg";
import AuthContext from "AuthContext";
import PinInputContainer from "../../components/PinInput";
import User from "User";

export default function VerificationPage() {
  const location = useLocation();
  const { phoneNumber } = location.state || {};
  const [otp, setOtp] = useState("");
  const [verificationError, setVerificationError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);
  const { t } = useTranslation();

  async function handleSubmit() {
    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/verifyOtp`,
        {
          phone: phoneNumber,
          otp,
        }
      );

      if (response.data.message === "Invalid OTP") {
        setOtp("");
        setVerificationError(t("Invalid OTP. Please enter the correct code."));
      } else if (response.data.message === "Login successfully") {
        const user = new User(response.data.user);
        localStorage.setItem("user", JSON.stringify(user));
        setIsAuthenticated(true);

        if (location.state?.fromResetPassword) {
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}api/resetPassword`,
            {
              phone: phoneNumber,
            }
          );
        }

        if (location.state?.fromQuiz) {
          navigate(location.state.result);
        } else {
          navigate("/", { state: { showPopup: true } });
        }
      }
    } catch (error) {
      setVerificationError(t("Invalid OTP. Please enter the correct code."));
    } finally {
      setLoading(false);
    }
  }

  const handleResendCode = async () => {
    try {
      setLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}api/resendOTP`,
        {
          phone: phoneNumber,
        }
      );

      if (!response.ok) {
        setVerificationError(t("unexpected_error"));
      } else {
        setVerificationError(null);
      }
    } catch (error) {
      setVerificationError(t("unexpected_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <div className="verification-container">
        <div className="confirm-text">{t("Confirmation")}</div>
        <div>
          <img src={Lock} alt={t("Lock icon")} className="bottom-image" />
        </div>
        <div className="hint-text">
          {t("Enter the 4-digit code sent to you")} <br />
          {phoneNumber && `${t("at")} ${phoneNumber}`}
        </div>
        <PinInputContainer value={otp} onChange={setOtp} className="pinCode" />
        {verificationError && (
          <div className="error-message">{verificationError}</div>
        )}
        <button
          className="submit"
          type="submit"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? t("Loading...") : t("NEXT")}
        </button>
        <button
          className="resend"
          onClick={handleResendCode}
          disabled={loading}
        >
          {t("Resend Code?")}
        </button>
      </div>
      {loading && (
        <div className="global-full-page">
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        </div>
      )}
    </main>
  );
}
