import React, { useEffect, useState, useContext } from "react";
import "../../styles/shipping.css";
import RightArrow from "../../assets/chevron-right-md-faded.svg";
import User from "User";
import Button from "../signUp/Button";
import RoseCircle from "../../assets/Ellipse76.svg";
import WhiteCircle from "../../assets/Ellipse 78.svg";
import { SyncLoader } from "react-spinners"; // Import the SyncLoader component from react-spinners
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer";
import { useNavigate, Link } from "react-router-dom";
import CounterBox from "components/CounterBox";
import BlackLine from "../../assets/Line 3.svg";
import CartContext from "CartContext";
import { useTranslation } from "react-i18next";
import { FaDotCircle } from "react-icons/fa";

const Shipping = () => {
  const navigate = useNavigate();
  const [responseDatas, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  const [products, setProducts] = useState(null);
  const [cartEmpty, setCartEmpty] = useState(false);
  const [subtotal, setSubtotal] = useState(null);
  const [cart, setCart] = useState(false);
  const { fetchCartCount } = useContext(CartContext);
  const [isKSA, setIsKsa] = useState(false);

  useEffect(() => {
    getShippmentInfo(); // Call handleSubmit when the component mounts
    handleGetCart();
  }, []);

  const generateGuestSessionId = () => {
    const randomNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
    return `guest_${randomNumber}`;
  };

  async function getShippmentInfo() {
    setLoading(true);
    let token;
    const user = User.fromLocalStorage();
    if (user) {
      token = user.api_token;
    } else {
      let guestSessionId = localStorage.getItem("guestId");
      if (guestSessionId === null) {
        guestSessionId = generateGuestSessionId();
        localStorage.setItem("guestId", guestSessionId);
      }
      token = guestSessionId;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/getShipmentInfo`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();
      setResponseData(responseData);

      if (responseData.error === "You dont have shipmet info.") {
        navigate(`/cart`);
        setCartEmpty(true);
      } else if (responseData.country === "SA") {
        setIsKsa(true);
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  }
  const handleGetCart = async () => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/viewCart`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();
      if (responseData.message === "Cart is empty") {
        navigate(`/cart`);
        setCartEmpty(true);
      } else {
        setSubtotal(responseData.sub_total);
        setProducts(responseData.cart);
        setCart(responseData);
      }
    } catch (error) {
      console.error("Error while viewing cart", error);
    } finally {
      setLoading(false);
    }
  };
  const editProductInCart = async (productId, quantity) => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/editCart?product_id=${productId}&quantities=${quantity}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        await handleGetCart();
      } else {
        console.error("Error updating product quantity:", response.statusText);
      }

      return responseData; // Return the response data
    } catch (error) {
      console.error("Error updating product quantity:", error);
    } finally {
      setLoading(false);
    }
  };
  const removeProductFromCart = async (productId) => {
    try {
      let token;
      const user = User.fromLocalStorage();
      if (user) {
        token = user.api_token;
      } else {
        let guestSessionId = localStorage.getItem("guestId");
        if (guestSessionId === null) {
          guestSessionId = generateGuestSessionId();
          localStorage.setItem("guestId", guestSessionId);
        }
        token = guestSessionId;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/removeProductFromCart?product_id=${productId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setLoading(true);
        handleGetCart();
        fetchCartCount();
      }
    } catch (error) {
      console.error("Error while removing product from cart", error);
    }
  };

  function handleSubmit() {
    navigate(`/cart/${id}/shipping/${responseDatas.id}`);
  }

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <main>
      <div className="mobile-shipping-page">
        <div className="row-drop2">
          <div className="red2"></div>
          <div className="light-gray2"></div>
        </div>

        <div className="ordermap2">
          <div className="Information2">{t("1. Information")}</div>
          <img
            src={RightArrow}
            alt="right arrow"
            width={16}
            height={16}
            className={i18n.language === "ar" ? "mirror" : ""}
          />
          <div className="shipping2">{t("2. Shipping")}</div>
          <img
            src={RightArrow}
            alt="right arrow"
            width={16}
            height={16}
            className={i18n.language === "ar" ? "mirror" : ""}
          />
          <div className="payment2">{t("3. Payment")}</div>
        </div>

        {loading ? ( // Show loading spinner if loading state is true
          <div
            style={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="loader-overlay">
              <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
            </div>
          </div>
        ) : (
          <div className="shipping-page-Container">
            <Link to={`/cart/${id}`} className="edit-shipping-page">
              {t("edit")}
            </Link>
            <div className="first-box-shipping-page">
              <div className="contact-shipping-page">
                <div className="first-box-title-shipping-page">
                  {t("contact")}
                </div>
                <div className="first-box-email-shipping-page">
                  {responseDatas?.email}{" "}
                </div>
              </div>
              <div className="black-line-shipping-page"></div>
              <div className="ship-to-shipping-page">
                <div className="first-box-title-shipping-page">
                  {t("shipTo")}
                </div>
                <div className="first-box-email-shipping-page">
                  {responseDatas?.appartment}, {responseDatas?.address},{" "}
                  {responseDatas?.city}, {responseDatas?.country}
                </div>
              </div>
            </div>
            <div className="shipping-method-text-page">
              {t("shippingMethod")}
            </div>
            <div className="second-box-shipping-page">
              <div
                className={`second-box-shipping-page-img ${
                  isArabic ? "arabic" : ""
                }`}
              >
                <FaDotCircle color="rgba(252, 219, 204, 1)" size={20} />
              </div>
              <div
                className={`second-box-shipping-page-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("standardShippment")}
              </div>
              <div className="second-box-shipping-page-price">
                {isKSA ? t("30SAR") : t("70SAR")}
              </div>
            </div>

            <Button
              className="continue-to-payment-button"
              text={t("continueToPayment")}
              onClick={handleSubmit}
            />

            <Link to={`/cart/${id}`}>
              <div className="Return-Order-Info-Container">
                <img
                  src={RightArrow}
                  alt="right arrow"
                  width={16}
                  height={16}
                  className={`arrow ${isArabic ? "arabic" : ""}`}
                />
                <div className="return-order-info">
                  {t("Return to information")}
                </div>
              </div>
            </Link>
          </div>
        )}
        <Footer />
      </div>

      <div className="pc-shipping-page">
        {loading ? ( // Show loading spinner if loading state is true
          <div className="loader-overlay">
            <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
          </div>
        ) : (
          <>
            <div className="Left-Align-shipping">
              <div className="shipping-page-Container">
                <Link to={`/cart`} className="edit-shipping-page">
                  {t("edit")}
                </Link>
                <div className="first-box-shipping-page">
                  <div className="contact-shipping-page">
                    <div className="first-box-title-shipping-page">
                      {t("contact")}
                    </div>
                    <div className="first-box-email-shipping-page">
                      {responseDatas?.email}{" "}
                    </div>
                  </div>
                  <div className="black-line-shipping-page"></div>
                  <div className="ship-to-shipping-page">
                    <div className="first-box-title-shipping-page">
                      {t("shipTo")}
                    </div>
                    <div className="first-box-email-shipping-page">
                      {responseDatas?.appartment}, {responseDatas?.address},{" "}
                      {responseDatas?.district}, {responseDatas?.city},{" "}
                      {responseDatas?.country}
                    </div>
                  </div>
                </div>
                <div className="shipping-method-text-page">
                  {t("shippingMethod")}
                </div>
                <div
                  className={`second-box-shipping-page ${
                    isArabic ? "arabic" : ""
                  }`}
                >
                  <div className="second-box-shipping-page-img">
                    <FaDotCircle color="rgba(252, 219, 204, 1)" size={20} />
                  </div>
                  <div
                    className={`second-box-shipping-page-text ${
                      isArabic ? "arabic" : ""
                    }`}
                  >
                    {t("standardShippment")}
                  </div>
                  <div className="second-box-shipping-page-price">
                    {isKSA ? t("30SAR") : t("70SAR")}
                  </div>
                </div>

                <div className="button-container-shipping">
                  <Button
                    className="continue-to-payment-button"
                    text={t("continueToPayment")}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
            <div className="Right-Align-shipping">
              <div>
                {products &&
                  products.length > 0 &&
                  products.map((product, index) => (
                    <div className="single-product-coloum-shipping" key={index}>
                      <div className="single-product-shipping">
                        {product.medias && product.medias.length > 0 && (
                          <img
                            src={
                              product.medias.find(
                                (media) => media.tag === "rectangle"
                              ).image_url
                            }
                            alt={product.tag}
                            height={130}
                          />
                        )}

                        <div
                          className={`single-coloum-shipping ${
                            isArabic ? "arabic" : ""
                          }`}
                        >
                          <div className="product-name-shipping">
                            {isArabic ? product.name_ar : product.name_en}
                          </div>
                          <div className="product-price-shipping">
                            {product.status === "Sale"
                              ? product.discountedPrice
                              : product.price}{" "}
                            {t("SAR")}
                          </div>

                          <div className="product-counter-shipping">
                            <CounterBox
                              ProductColor={"rgb(252, 219, 204)"}
                              quantity={product.quantity}
                              productId={product.id}
                              productLimit={product.product_limit}
                              onUpdate={(productId, quantity) =>
                                editProductInCart(productId, quantity)
                              }
                            />
                            <div
                              className="product-remove-shipping"
                              onClick={() => removeProductFromCart(product.id)}
                              style={{ cursor: "pointer" }}
                            >
                              {t("remove")}
                            </div>
                          </div>
                        </div>
                      </div>
                      {index < products.length && (
                        <img
                          src={BlackLine}
                          alt="blackLine"
                          className="blackLine"
                        />
                      )}
                    </div>
                  ))}
                <div className="subtotal-container-pc">
                  {!cartEmpty && (
                    <>
                      <div className="row-pc">
                        <div className="title-pc">{t("subtotal")}</div>
                        <div className="value-pc">
                          {subtotal} {t("SAR")}
                        </div>
                      </div>

                      <div className="row-pc">
                        <div className="title-pc">{t("shippingFees")}</div>
                        <div className="value-pc">-</div>
                      </div>
                      <div className="row-pc">
                        <div className="title-pc">{t("total")}</div>
                        <div className="value-pc">
                          {subtotal} {t("SAR")}
                        </div>
                      </div>
                    </>
                  )}
                  <img src={BlackLine} alt="blackLine" className="blackLine" />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  );
};

export default Shipping;
