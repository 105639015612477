import React, { useEffect } from "react";

const TamaraSummaryWidget = ({
  lang = "en",
  country = "SA",
  amount,
  customCss = "",
  style = {},
}) => {
  useEffect(() => {
    window.tamaraWidgetConfig = {
      lang,
      country,
      publicKey: process.env.REACT_APP_TAMARA_PUBLIC_KEY,
      css: customCss,
      style,
    };

    if (window.TamaraWidgetV2) {
      window.TamaraWidgetV2.refresh();
    }
  }, [lang, country, customCss, style]);

  return (
    <tamara-widget
      type="tamara-summary"
      inline-type="2"
      amount={amount}
    ></tamara-widget>
  );
};

export default TamaraSummaryWidget;
