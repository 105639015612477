import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";
import "./../styles/SpinTheWheel.css";
import pointer from "./../assets/pointer.png";
import CloseBtnSvg from "../assets/close-btn.svg";
import User from "User";
import { useNavigate } from "react-router-dom";
import Confetti from "react-confetti";

const SpinTheWheel = ({ rewards, onClose }) => {
  const data = rewards.map((reward, index) => ({
    option: reward.name,
    style: {
      backgroundColor: index % 2 === 0 ? "#f98882" : "#fcbab6",
      color: "white",
    },
    probability: parseFloat(reward.probability),
    id: reward.id,
  }));

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [error, setError] = useState(null);
  const [rewardResponse, setRewardResponse] = useState(null);
  const [promocode, setPromocode] = useState(null);

  const navigate = useNavigate();
  const user = User.fromLocalStorage();

  const calculatePrize = () => {
    const weights = data.map((item) => item.probability);
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    const random = Math.random() * totalWeight;
    let cumulativeWeight = 0;

    for (let i = 0; i < weights.length; i++) {
      cumulativeWeight += weights[i];
      if (random <= cumulativeWeight) {
        return data[i].id;
      }
    }
    // TODO make default prize next luck next time
    return data[0].id;
  };

  const handleWheelClick = async () => {
    if (!user) {
      onClose();
      navigate("/login");
      return;
    }
    const hasClaimedPrize = localStorage.getItem("hasClaimedPrize");
    if (hasClaimedPrize === "true") {
      setError("You have already claimed your reward. You cannot spin again.");
      return;
    }

    const selectedId = calculatePrize();
    const prizeIndex = data.findIndex((item) => item.id === selectedId);

    setPrizeNumber(prizeIndex);
    setMustSpin(true);
  };

  const handleOnStopSpinning = async () => {
    const token = user.api_token;
    const selectedReward = data[prizeNumber];

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/submitReward`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ reward_id: selectedReward.id }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        setError(responseData.message || "Failed to claim reward.");
        return;
      }
      setRewardResponse(responseData.reward_name);
      if (responseData.promoCode) {
        setPromocode(responseData.promoCode);
      }
      localStorage.setItem("hasClaimedPrize", "true");
    } catch (error) {
      setError("An unexpected error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div className="blurry-background" onClick={onClose}></div>
      <div className="wheel-container">
        <div className="spin-close-button" onClick={onClose}>
          <img src={CloseBtnSvg} alt="Close" />
        </div>
        {!rewardResponse ? (
          <>
            <div className="wheel-title">
              It’s Time to Spin & Win Holiday Surprises
            </div>
            <div className="wheel-subtitle">
              Unlock exclusive holiday rewards, from discounts to free gifts.
              Don’t miss out!
            </div>
            <div onClick={handleWheelClick} className="wheel-spin-area">
              <Wheel
                mustStartSpinning={mustSpin}
                prizeNumber={prizeNumber}
                data={data}
                onStopSpinning={handleOnStopSpinning}
                outerBorderWidth={5}
                outerBorderColor="#fcdacd"
                innerBorderColor="#fcdacd"
                innerBorderWidth={20}
                radiusLineWidth={0}
                textColors={["white"]}
                backgroundColors={["#f98882", "#fcbab6"]}
                fontSize={10}
                fontFamily="Work Sans"
                fontWeight={500}
                pointerProps={{
                  src: pointer,
                }}
              />
            </div>
          </>
        ) : rewardResponse ? (
          <>
            <div className="confetti-wrapper">
              <Confetti
                width={window.innerHeight * 0.95}
                height={window.innerHeight * 0.7}
                numberOfPieces={1000}
                colors={["#FFD86F", "#FFEAB2", "#E2B742", "#FFE8AB", "#A88116"]}
              />
            </div>
            <div className="reward-display">{rewardResponse}</div>
            {promocode && (
              <div className="promo-code-reward">USE CODE: {promocode}</div>
            )}
          </>
        ) : null}
        {error && <div className="error-message">{error}</div>}
      </div>
    </>
  );
};

export default SpinTheWheel;
