import React, { useContext } from "react";
import CartContext from "CartContext";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

const AddToCart = ({ buttonColor, product, setLoading }) => {
  const { addToCart } = useContext(CartContext);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const handleAddToCart = async (productId, event) => {
    event.preventDefault();
    setLoading(true);

    try {
      await addToCart(productId, 1);
      ReactGA.initialize("G-3KMJ2EVWXY");
      ReactGA.event({
        category: "User",
        action: "Clicked a button",
      });
    } catch (error) {
      console.error("Error while adding the product to the cart:", error);
    } finally {
      setLoading(false);
    }
  };

  const isSoldOut = product.status === "Sold out";

  return (
    <button
      className={`add-to-cart ${isArabic ? "arabic" : ""}`}
      style={{
        background: isSoldOut ? "rgba(196, 192, 192, 1)" : buttonColor,
        cursor: isSoldOut ? "not-allowed" : "pointer",
      }}
      onClick={isSoldOut ? null : (event) => handleAddToCart(product.id, event)}
      disabled={isSoldOut}
    >
      {isSoldOut ? (
        <>{t("out_of_stock")}</>
      ) : product.type === "bundle" ? (
        <>
          {t("add_to_cart")} -{" "}
          <span
            style={{
              marginRight: isArabic ? "0px" : "7px",
              marginLeft: isArabic ? "7px" : "0px",
            }}
            className="strikethrough"
          >
            {product.id === 15 ? "793" : product.id === 16 ? "698" : "304"}{" "}
            {t("SAR")}
          </span>
          {product.price} {t("SAR")}
        </>
      ) : product.status === "Sale" && product.discounted_price ? (
        <>
          {t("add_to_cart")} -{" "}
          <span
            style={{
              marginRight: isArabic ? "0px" : "7px",
              marginLeft: isArabic ? "7px" : "0px",
            }}
            className="strikethrough"
          >
            {product.price} {t("SAR")}
          </span>
          {product.discounted_price} {t("SAR")}
        </>
      ) : (
        <>
          {t("add_to_cart")} - {product.price} {t("SAR")}
        </>
      )}
    </button>
  );
};

export default AddToCart;
