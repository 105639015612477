import React, { useState, useEffect } from "react";
import "../../styles/orderSuccess.css";
import { FaCheckCircle } from "react-icons/fa";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "components/Footer";
import { SyncLoader } from "react-spinners";
import ErrorImage from "./../../assets/error.png";

const TamaraOrderSuccess = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [order, setOrder] = useState({});
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    handleGetOrder();
  }, []);

  const handleGetOrder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/fetchOrderDetailsById?order_id=${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tamara: true,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Order not found");
      }

      const responseData = await response.json();
      setOrder(responseData);
      console.log(responseData);
    } catch (error) {
      console.error("Error while fetching order details:", error);
      setErrorMsg(t("Order_Not_Found_Message"));
    }
  };

  if (errorMsg) {
    return (
      <main className="error-container">
        <img src={ErrorImage} alt="Error" className="error-image" />
        <div className="error-text">{errorMsg}</div>
        <Footer />
      </main>
    );
  }

  if (!order.order) {
    return (
      <main className="global-full-page">
        <div className="loader-overlay">
          <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
        </div>
      </main>
    );
  }

  return (
    <>
      <div className="orderConfirmation-container">
        <div className="orderConfirmation-Left-Side">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FaCheckCircle className="orderConfirmation-corect-sign" />
          </div>
          <div className="order-Confirmed-title">
            {t("Order_Confirmed_Title")}
          </div>
          <div className="order-Confirmed-text-body">
            {t("Order_Confirmed_Text_Body")}
          </div>
          <Link to="/products">
            <div className="continue-shopping-button-OC">
              {t("Continue_Shopping_Button")}
            </div>
          </Link>
        </div>
      </div>

      <div className="orderConfirmation-mobile">
        <div className="orderConfirmation-mobile-container">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FaCheckCircle className="orderConfirmation-corect-sign" />
          </div>
          <div
            style={{ textAlign: "center" }}
            className="order-Confirmed-title"
          >
            {t("Order_Confirmed_Title")}
          </div>
          <div className="order-Confirmed-text-body">
            {t("Order_Confirmed_Text_Body")}
          </div>
          <Link to="/products">
            <div className="continue-shopping-button-OC">
              {t("Continue_Shopping_Button")}
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TamaraOrderSuccess;
